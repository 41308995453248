exports.components = {
  "component---src-components-blog-blog-post-js": () => import("./../../../src/components/blog/blogPost.js" /* webpackChunkName: "component---src-components-blog-blog-post-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-benefits-js": () => import("./../../../src/pages/benefits.js" /* webpackChunkName: "component---src-pages-benefits-js" */),
  "component---src-pages-benefits-pl-js": () => import("./../../../src/pages/benefits.pl.js" /* webpackChunkName: "component---src-pages-benefits-pl-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-pl-js": () => import("./../../../src/pages/blog.pl.js" /* webpackChunkName: "component---src-pages-blog-pl-js" */),
  "component---src-pages-demo-2-d-ar-solution-js": () => import("./../../../src/pages/demo/2d-ar-solution.js" /* webpackChunkName: "component---src-pages-demo-2-d-ar-solution-js" */),
  "component---src-pages-demo-2-d-ar-solution-pl-js": () => import("./../../../src/pages/demo/2d-ar-solution.pl.js" /* webpackChunkName: "component---src-pages-demo-2-d-ar-solution-pl-js" */),
  "component---src-pages-demo-3-d-ar-solution-js": () => import("./../../../src/pages/demo/3d-ar-solution.js" /* webpackChunkName: "component---src-pages-demo-3-d-ar-solution-js" */),
  "component---src-pages-demo-3-d-ar-solution-pl-js": () => import("./../../../src/pages/demo/3d-ar-solution.pl.js" /* webpackChunkName: "component---src-pages-demo-3-d-ar-solution-pl-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-faq-pl-js": () => import("./../../../src/pages/faq.pl.js" /* webpackChunkName: "component---src-pages-faq-pl-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-pl-js": () => import("./../../../src/pages/index.pl.js" /* webpackChunkName: "component---src-pages-index-pl-js" */),
  "component---src-pages-make-an-appointment-js": () => import("./../../../src/pages/make-an-appointment.js" /* webpackChunkName: "component---src-pages-make-an-appointment-js" */),
  "component---src-pages-make-an-appointment-pl-js": () => import("./../../../src/pages/make-an-appointment.pl.js" /* webpackChunkName: "component---src-pages-make-an-appointment-pl-js" */),
  "component---src-pages-possibilities-js": () => import("./../../../src/pages/possibilities.js" /* webpackChunkName: "component---src-pages-possibilities-js" */),
  "component---src-pages-possibilities-pl-js": () => import("./../../../src/pages/possibilities.pl.js" /* webpackChunkName: "component---src-pages-possibilities-pl-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-pl-js": () => import("./../../../src/pages/services.pl.js" /* webpackChunkName: "component---src-pages-services-pl-js" */),
  "component---src-pages-vto-js": () => import("./../../../src/pages/vto.js" /* webpackChunkName: "component---src-pages-vto-js" */),
  "component---src-pages-vto-pl-js": () => import("./../../../src/pages/vto.pl.js" /* webpackChunkName: "component---src-pages-vto-pl-js" */)
}

